exports.components = {
  "component---src-components-guide-singola-guida-js": () => import("./../../../src/components/Guide/SingolaGuida.js" /* webpackChunkName: "component---src-components-guide-singola-guida-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avviso-js": () => import("./../../../src/pages/avviso.js" /* webpackChunkName: "component---src-pages-avviso-js" */),
  "component---src-pages-corso-js": () => import("./../../../src/pages/corso.js" /* webpackChunkName: "component---src-pages-corso-js" */),
  "component---src-pages-guida-js": () => import("./../../../src/pages/guida.js" /* webpackChunkName: "component---src-pages-guida-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offerta-pnrr-js": () => import("./../../../src/pages/offerta-pnrr.js" /* webpackChunkName: "component---src-pages-offerta-pnrr-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

